import apiHelper from './index';

async function getRTCServers(iceOptions, spinner = false) {
  if (!iceOptions)
    iceOptions = ''
    
  const [error, data] = await apiHelper.get(`offer/rtc_server_list/?server=${iceOptions}` , spinner);
  if (error) throw 'Error occurred while fetching servers';

  return data;
}

async function sendRTCOffer(payload, spinner = false) {
  return await apiHelper.post(`offer/create_new_offer/`, payload, false);
}

async function getRTCAnswer(connectionID) {
  return await apiHelper.get(`offer/update_offer/${connectionID}`, false);
}

async function closeConnection(connectionID, payload) {
  return await apiHelper.put(
    `offer/update_offer/${connectionID}`,
    payload,
    false
  );
}

async function getRTCOffer(uuid) {
  return await apiHelper.get(`offer/${uuid}`, false);
}
async function deleteRTCOffer(uuid) {
  return await apiHelper.remove(`offer/${uuid}`, false);
}

export default {
  getRTCServers,
  sendRTCOffer,
  getRTCAnswer,
  closeConnection,
  getRTCOffer,
  deleteRTCOffer
};
