'use-strict';

import auth from 'src/services/auth';
import fcm from 'src/services/fcm';
import { v4 as uuidv4 } from 'uuid';
import webRtc from 'src/services/webrtc';

class BaseRTCConnection {
  static sdpStrings = {
    type: 'type',
    sdp: 'sdp'
  };
  static iceGatheringStates = {
    new: 'new',
    complete: 'complete',
    gathering: 'gathering'
  };
  constructor(device, rtcConfiguration, connectionChangeCallback) {
    this._id = undefined;
    this.offer = undefined;
    this.offerStrings = undefined;
    this.transceiver = undefined;
    this.timeoutId = undefined;

    this.device = device;
    this.configuration = rtcConfiguration;
    this.connection = new RTCPeerConnection(this.configuration);

    if (connectionChangeCallback)
      this.connection.oniceconnectionstatechange = e =>
        connectionChangeCallback(this.connection.iceConnectionState);
  }
  async connectToRemotePeer() {
    await this.createOffer();
    this.modifyOffer();
    await this.dispatchOffer();
    await this.waitForAnswer();
  }
  async createOffer() {
    const localDescription = await this.connection.createOffer();
    await this.connection.setLocalDescription(localDescription);
    await this.gatherIceStates();
    this.offer = {
      device: this.device,
      ...this.connection.localDescription.toJSON()
    };
  }

  async gatherIceStates() {
    const { connection } = this;
    const { complete } = BaseRTCConnection.iceGatheringStates;
    return new Promise((resolve, reject) => {
      if (connection.iceGatheringState === complete) {
        resolve(true);
      } else {
        const checkState = () => {
          if (connection.iceGatheringState === complete) {
            connection.removeEventListener(
              'icegatheringstatechange',
              checkState
            );
            resolve(true);
          }
        };
        connection.addEventListener('icegatheringstatechange', checkState);
      }
    });
  }

  //Override in base class to add custom args to offer
  modifyOffer() {}

  async dispatchOffer() {
    this.id = uuidv4();
    this.offer.id = this.id;
    this.offer.device_token = this.fcmToken;

    const opcua_tag_payload = {
      data: { device_token: this.offer.device_token },
      type: 'discover_devices_opcua',
      message_time: new Date().toISOString(),
      platform: 'portal',
      organization: this.organization,
      device_id: this.offer.device
    };
    await fcm.sendMessageToRabbitMQ(opcua_tag_payload, false);

    const payload = {
      data: this.offer,
      type: 'webrtc_stream_offer',
      message_time: new Date().toISOString(),
      platform: 'portal',
      organization: this.organization,
      device_id: this.offer.device
    };
    const [error, data] = await fcm.sendMessageToRabbitMQ(payload, false);
    if (error) throw 'Error while sending offer to remote peer';
  }

  async waitForAnswer() {
    const [error, data] = await webRtc.getRTCOffer(this.id);
    console.log(this.id, data, !data);

    // if (error) throw 'Error occurred connecting to remote peer.';
    if (Object.keys(data).length !== 0) {
      const { type, sdp } = data;
      const { answer } = this.offerStrings;
      if (type === answer) {
        await this.connection.setRemoteDescription({
          type: 'answer',
          sdp: sdp + '\r\n'
        });
      }
    } else {
      this.timeoutId = setTimeout(this.waitForAnswer.bind(this), 2000);
    }
  }

  async setRTCRemoteDescription(data) {
    console.log('In Media setRTCRemoteDescription', data);

    const { type, sdp } = data;
    const { answer } = this.offerStrings;

    if (type === answer) {
      await this.connection.setRemoteDescription({
        type: 'answer',
        sdp: sdp
      });
    }
  }

  async deleteRTCOffersFromDB() {
    const [error, data] = await webRtc.deleteRTCOffer(this.id);
    if (error) throw 'Error while deleting offers from Database';
  }

  close() {
    const data = {
      id: this.id,
      device: this.device,
      sdp: '',
      type: 'close'
    };
    const payload = {
      data: data,
      type: 'webrtc_stream_offer',
      message_time: new Date().toISOString(),
      platform: 'portal',
      organization: this.organization,
      device_id: this.offer?.device
    };
    fcm.sendMessageToRabbitMQ(payload, false);
    this.deleteRTCOffersFromDB();
    clearInterval(this.timeoutId);
  }
}

export class MediaRTCConnection extends BaseRTCConnection {
  constructor(
    device,
    organization,
    fcmToken,
    video,
    rtcConfiguration,
    connectionChangeCallback = null
  ) {
    super(device, rtcConfiguration, connectionChangeCallback);

    this.fcmToken = fcmToken;
    this.organization = organization;
    this.offerStrings = {
      offer: 'offer',
      answer: 'answer'
    };
    this.video = video;
    this.addTrackEvent();
    this.connection.addTransceiver('video', { direction: 'recvonly' });
  }

  async dispatchOffer() {
    this.id = uuidv4();
    this.offer.id = this.id;
    this.offer.device_token = this.fcmToken;
    const payload = {
      data: this.offer,
      type: 'webrtc_stream_offer',
      message_time: new Date().toISOString(),
      platform: 'portal',
      organization: this.organization,
      device_id: this.offer.device
    };
    const [error, data] = await fcm.sendMessageToRabbitMQ(payload, false);
    if (error) throw 'Error while sending offer to remote peer';
    // this._id = data.id;
  }

  async setRTCRemoteDescription(data) {
    console.log('In Media setRTCRemoteDescription', data);

    // const [error, data] = await WebRTC.getRTCAnswer(this._id);
    // if (error) throw 'Error occurred connecting to remote peer.';

    const { type, sdp } = data;
    const { answer } = this.offerStrings;

    if (type === answer) {
      await this.connection.setRemoteDescription({
        type: 'answer',
        sdp: sdp
      });
    }
  }

  addTrackEvent() {
    const self = this;
    this.connection.addEventListener('track', evt => {
      if (evt.track.kind === 'video') {
        self.video.srcObject = evt.streams[0];
      }
    });
  }
}

export class RtcConnection extends BaseRTCConnection {
  constructor(
    device,
    organization,
    fcmToken,
    rtcConfiguration,
    connectionChangeCallback,
    defaultChannels = true
  ) {
    super(device, rtcConfiguration, connectionChangeCallback);
    this.organization = organization;
    this.fcmToken = fcmToken;
    this.offerStrings = {
      offer: 'offer-dc',
      answer: 'answer-dc'
    };
    this.channelNames = {
      fileChannel: 'fileChannel',
      jsonChannel: 'jsonChannel'
    };
    this.channels = {};

    if (defaultChannels)
      Object.keys(this.channelNames).forEach(name =>
        this.createDataChannel(name)
      );
  }

  async dispatchOffer() {
    this.id = uuidv4();
    this.offer.id = this.id;
    this.offer.device_token = this.fcmToken;
    const payload = {
      data: this.offer,
      type: 'webrtc_stream_offer',
      message_time: new Date().toISOString(),
      platform: 'portal',
      organization: this.organization,
      device_id: this.offer.device
    };
    const [error, data] = await fcm.sendMessageToRabbitMQ(payload, false);
    if (error) throw 'Error while sending offer to remote peer';
    // this._id = data.id;
  }

  async setRTCRemoteDescription(data) {
    console.log('In setRTCRemoteDescription', data);

    // const [error, data] = await WebRTC.getRTCAnswer(this._id);
    // if (error) throw 'Error occurred connecting to remote peer.';

    const { type, sdp } = data;
    const { answer } = this.offerStrings;

    if (type === answer) {
      await this.connection.setRemoteDescription({
        type: 'answer',
        sdp: sdp
      });
    }
  }

  modifyOffer() {
    if (!this.offer) throw 'Offer not yet created.';
    this.offer[BaseRTCConnection.sdpStrings.type] = this.offerStrings['offer'];
  }

  createDataChannel(channelName) {
    this.channels[channelName] = this.connection.createDataChannel(channelName);
  }
  getJSONChannel() {
    return this.channels[this.channelNames.jsonChannel];
  }
  getFileChannel() {
    return this.channels[this.channelNames.fileChannel];
  }
}
